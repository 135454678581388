.pair-accordion ion-icon {
  color: var(--ion-color-primary) !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.serial-number-submit {
  height: 49px;
  margin-top: 0;
  width: 100%;
  max-width: 200px;
  text-transform: capitalize;
}

ion-item.serial-number-input, ion-item.pin-input {
  border: 1px solid #cccccc;
  border-radius: 6px;
}

ion-item.serial-number-input ion-icon {
  margin-right: 10px;
}

.standard-container .standard-container-content.pair-select-serial-number {
  padding: 0 0 40px 0;
}

ion-accordion.accordion-expanding > [slot=header] .ion-accordion-toggle-icon, ion-accordion.accordion-expanded > [slot=header] .ion-accordion-toggle-icon {
  transform: rotate(270deg);
  margin-right: 10px;
}

.ion-accordion-toggle-icon {
  margin-right: 10px;
}

.pair-accordion .seat-image {
  width: 70%;
  margin: 0 auto;
}

