.login-screen ion-grid {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.login-screen ion-row {
  flex-grow: 1;
}

.login-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-screen ion-img {
  max-width: 600px;
}

ion-col.login-form-column {
  max-width: 400px;
}

ion-col.login-form-column ion-content.authentication-form {
  --background: #F8F8F8;
  border-left: 1px solid #dbdbdb;
}

@media (max-width:768px)  {
  .login-screen .desktop {
    display:none;
  }
  ion-col.login-form-column {
    width: 100%;
    max-width: 100%;
  }
  ion-col.login-form-column ion-content.authentication-form {
    border: none;
  }
}
