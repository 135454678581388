.authentication-form-text {
  background-color: transparent;
  border: none;
  box-shadow: none;
  text-align: center;
}

.authentication-form {
    margin: auto;
    padding: 0 8%;
}

.authentication-form ion-item {
    --background: var(--cas-input-background);
    margin-top: 1rem;
    color: var(--cas-input-color);
}

.authentication-form ion-button {
    margin-top: 1rem;
    width: 100%;
    text-transform: capitalize;
}

.authentication-form a{
    text-decoration: none;
}

.authentication-form .forgot-password,
.authentication-form p,
.authentication-form ion-item ion-icon {
    color: var(--ion-color-medium);
}

.authentication-form ion-img.casana-logo {
    width: 75px;
    margin: 7vh auto;
}
