.seat-debug {
  max-height: 100%;
  height: 100%
}

.seat-debug-header {
  height: 100px;
  background-color: var(--ion-color-primary);
  color: var(--body-background-color);
  position: relative;
}

.seat-debug-header ion-title {
  position: absolute;
  bottom: 15px;
  font-size: 30px;
  font-family: var( --ion-font-family);
}

.seat-options ion-row {
  display: flex;
  justify-content: space-between;
  width: 100%
}

.seat-options ion-button{
  width:20%;
}

.seat-data {
  display: table;
  border-collapse: collapse;
  float: left;
  margin: 10px 10px;
  --background: var(--body-background-color)
}

.seat-data ion-col {
  display: table-cell;
  border: 1px solid var(--body-background-color);
  margin: -1px 0 0 -1px;
  font-family: var( --ion-font-family);
}

.seat-log {
  margin: 5px;
  min-height: 100px;
}

.seat-log ion-title {
  padding: 0;
  margin: 0;
}

.seat-log ion-button {
  width: 100px;
  float:right;
}

.seat-log .log-data{
  background: var(--body-background-color);
  border-radius: 5px;
  margin: 10px auto;
  height: 300px;
  display: block;
  padding: 16px;
  overflow: auto;
  align-items: flex-start;
}

.log {
  --background: var(--body-background-color);
  font-size: 14px;
  font-family: monospace;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
}

.log-debug {
  color: var(--log-debug);
}

.log-error {
  color: var(--log-error);
}

.log-warn {
  color: var(--log-warn);
}

.log-info {
  color: var(--log-info);
}

.log-panic {
  color: var(--log-panic);
}

.log-success {
  color: var(--log-success);
}
