.init-container {
  background: transparent;
}

.pair-accordion ion-icon, .pair-accordion-text{
  color: var(--ion-color-primary) !important;
}

.initialize-link {
  cursor: pointer;
  margin-left: 15px;
}

.initialize-timer, .initialize-screen {
  padding-top: 0;
  padding-bottom: 100px;
}

.timer-btn {
  width: 80px;
  text-transform: capitalize;
}

.timer-btn.reset {
  --background: var(--ion-color-danger);
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.retake-recording {
  --background: var(--cas-input-background);
  border: 1px solid var(--ion-color-primary);
  box-shadow: none;
  color: var(--ion-color-primary);
}
.retake-recording, .upload-recording {
  width: 100%;
  max-width: 200px;
  text-transform: inherit;
  cursor: pointer;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.initialize-form-submit {
  width: 100%;
  text-transform: capitalize;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

#sit-timer-duration {
  margin: auto;
  text-align: center;
  border: 2px solid lightgrey;
  border-radius: 4px;
  width: 164px;
}

.sit-session-list {
  background-color: #FFFFFF;
}

.init-form ion-item {
  border-top: 1px solid #F0F0F0;
  border-left: 1px solid #F0F0F0;
  border-right: 1px solid #F0F0F0;
  border-radius: 5px;
}

ion-text.blockquote{
  padding: 0 .5rem;
  margin: 0 0 5rem;
  border-left: 4px solid var(--ion-color-step-550, #b7b7b7);
  display: block;
}
