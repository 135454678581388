body {
  background: white;
}

.container {
  width: 100%;
  height: calc(100% - 20px);
}

.flash-message {
  width: 80%;
  text-align: left;
  background-color: transparent;
  box-shadow: none;
  overflow: inherit;
  position: relative;
  left: 0;
  right: 0;
  margin: 5px auto;
}

.flash-message ion-text {
  display: block;
  border-radius: 6px;
  padding: 10px;
}

.flash-message ion-text.warning {
  border-radius: 6px;
  border: 1px solid var(--ion-color-warning-shade);
  background-color: rgba(var(--ion-color-warning-rgb), 60%);
  color: var(--ion-color-warning-shade);
}

.flash-message ion-text.danger {
  border-radius: 6px;
  border: 1px solid var(--ion-color-danger-shade);
  background-color: rgba(var(--ion-color-danger-rgb), 60%);
  color: var(--ion-color-danger-shade);
}

.flash-message ion-text.success {
  border-radius: 6px;
  border: 1px solid var(--ion-color-success-shade);
  background-color: rgba(var(--ion-color-success-rgb), 60%);
  color: var(--ion-color-success-shade);
}

.standard-container {
  padding: 0 40px;
  text-align: left;
  width: 80%;
  margin: 20px auto 10vh auto;
  border: 1px solid #dbdbdb;
  background-color: var(--card-background-color);
  border-radius: 6px;
  color: #8c8c8c;
  position: relative;
  box-shadow: none;
}

.standard-container ion-card-header {
  margin-top: 15px;
}

.standard-container .standard-container-content {
  height: calc(100% - 85px);
  overflow-y: scroll;
  padding: 0 0 100px 0;
}

.standard-container .wifi-page {
  padding-bottom: 100px;
}

.standard-container .standard-container-footer {
  position: absolute;
  bottom: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #dbdbdb;
  padding: 11px 0 0 0;
  width: calc(100% - 80px);
  height: 70px;
  text-align: right;
  background-color: var(--card-background-color);
}

.standard-container .standard-container-footer.hide-buttons {
  display: none;
}

.standard-container .standard-container-footer .btn {
  font-family: "Montserrat",sans-serif;
  letter-spacing:normal;
  width: 50%;
  border-radius: 6px;
  max-width: 130px;
  display: inline-block;
  text-transform:capitalize;
}

.standard-container .standard-container-footer .btn.btn-next {
  margin-left: 20px;
}

.standard-container ion-grid {
  padding: 0;
}

.standard-container ion-grid ion-col {
  padding: 5px 10px;
  display: inline-block;
}

.standard-container ion-grid ion-col:last-of-type {
  padding-right: 0;
}

.standard-container ion-accordion-group ion-item.ion-color-light {
  --ion-color-base: transparent;
}

@media (max-width:768px)  {
  .standard-container ion-grid ion-col {
    padding: 5px 0;
    display: inline-block;
  }
}

@media (max-width:602px)  {
  .container {
    width: 100%;
    height: 100%;
  }
  .flash-message {
    width: calc(100% - 20px);
    margin: 10px 10px;
    height: 60px;
    background-color: var(--card-background-color);
    z-index: 100;
  }
  .standard-container {
    margin-top: 0;
    margin-bottom: 0;
    padding: 70px 20px 0 20px;
    width: 100%;
    height: 100vh;
    border: none;
    border-radius: 0;
  }
  .standard-container .standard-container-footer {
    position: absolute;
    bottom: 15px;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #dbdbdb;
    padding: 15px 0 0 0;
    width: calc(100% - 40px);
    height: 70px;
    text-align: center;
  }
  .standard-container .standard-container-footer .btn {
    font-size: 14px;
  }
}


