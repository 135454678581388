.standard-container ion-label {
  font-family: "Montserrat",sans-serif;
  letter-spacing:normal;
  font-size: 14px;
  color: #454545;
  margin-bottom: 3px;
  margin-top: 10px;
  display: inline-block;
}

.standard-container .casana-form ion-item {
  border: 1px solid var(--ion-border-color);
  border-radius: 4px;
  display: inline-block;
  width: 95%;
}

.standard-container .casana-form ion-label {
  display: block;
}

.standard-container .casana-form .blood-pressure-row {
  line-height: 50px;
  font-size: 22px;
}
.standard-container .casana-form .blood-pressure-row ion-input {
  line-height: 16px;
}

.standard-container ion-label ion-icon.required {
  color: var(--ion-color-danger-shade);
  font-size: 10px;
  vertical-align: super;
  padding-right: 3px
}

.standard-container ion-grid.wifi-form {
  padding-bottom: 40px;
}
