ion-card-title {
  font-family: "Montserrat",sans-serif;
  color: #454545;
  font-size: 34px;
}

ion-card-subtitle {
  font-family: "Montserrat",sans-serif;
  color: var(--ion-color-medium);
  font-size: 16px;
}

ion-card-content.card-content-md {
  box-sizing: border-box;
  overflow: hidden;
}

ion-card-content ion-text.help-link {
  font-family: "Montserrat",sans-serif;
  color: var(--ion-color-primary);
  font-size: 16px;
}

ion-card-content#help-content {
  display:none;
}

ion-card-content ion-text {
  font-family: "Montserrat",sans-serif;
  color: var(--ion-color-medium);
  font-size: 16px;
}

ion-button {
  font-family: "Montserrat",sans-serif;
  font-size: 15px;
  letter-spacing: normal;
  width: 50%;
  border-radius: 6px;
  font-weight: normal;
}

:root {
  --ion-color-help: transparent;
  --ion-color-help-rgb: transparent;
  --ion-color-help-contrast: var(--ion-color-primary);
  --ion-color-help-contrast-rgb: transparent;
  --ion-color-help-shade: transparent;
  --ion-color-help-tint: transparent;
}

.ion-color-help {
  --ion-color-base: var(--ion-color-help);
  --ion-color-base-rgb: var(--ion-color-help-rgb);
  --ion-color-contrast: var(--ion-color-help-contrast);
  --ion-color-contrast-rgb: var(--ion-color-help-contrast-rgb);
  --ion-color-shade: var(--ion-color-help-shade);
  --ion-color-tint: var(--ion-color-help-tint);
}

.item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
  font-family: "Montserrat",sans-serif;
  font-size: 16px;
}

.ion-color-help ion-icon[slot="start"] {
  margin-inline-end: 10px;
}
ion-accordion-group ion-accordion ion-text {
  font-family: "Montserrat",sans-serif;
  color: var(--ion-color-medium);
  font-size: 14px;
  display: inline-block;
}

ion-accordion {
  margin: 0 auto;
}

ion-accordion.accordion-collapsing ion-item[slot="header"],
ion-accordion.accordion-collapsed ion-item[slot="header"],
ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
  --background: var(--card-background-color);
  color: var(--ion-color-medium);
  --ripple-color: transparent;
}

ion-accordion.accordion-expanding > [slot=header] .ion-accordion-toggle-icon, ion-accordion.accordion-expanded > [slot=header] .ion-accordion-toggle-icon {
  transform: rotate(90deg)!important;
  --ripple-color: transparent;
}

ion-note {
  line-height: 14px;
}

::slotted(ion-icon[slot="start"]) {
  margin-right: unset;
  margin-inline-end: 12px!important;
}

@media (max-width:602px)  {
  ion-card ion-card-title {
    font-size: 28px;
    margin-bottom: 12px;
  }

  ion-card ion-card-subtitle {
    font-family: "Montserrat",sans-serif;
    color: var(--ion-color-medium);
    font-size: 14px;
  }
}

body {
  font-family: "Roboto",sans-serif;
  color: var(--ion-color-medium);
  --background: #F0F0F0;
}

ion-content {
  --background: #F0F0F0;
}

ion-item.item-input ion-label ion-icon {
  color: rgb(206, 206, 206);
}

.color-app {
  color: var(--ion-color-primary);
}

.d-flex {
  display: flex !important;
}

/** TOP MARGINS */
.m-t-1 {
  margin-top: 1px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-t-100 {
  margin-top: 100px;
}
/** BOTTOM MARGINS */
.m-b-1 {
  margin-bottom: 1px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-b-100 {
  margin-bottom: 100px;
}
/** LEFT MARGINS */
.m-l-1 {
  margin-bottom: 1px;
}
.m-l-5 {
  margin-bottom: 5px;
}
.m-l-10 {
  margin-bottom: 10px;
}
.m-l-15 {
  margin-bottom: 15px;
}
.m-l-20 {
  margin-bottom: 20px;
}
.m-l-25 {
  margin-bottom: 25px;
}
.m-l-30 {
  margin-bottom: 30px;
}
.m-l-40 {
  margin-bottom: 40px;
}
.m-l-50 {
  margin-bottom: 50px;
}
.m-l-100 {
  margin-bottom: 100px;
}
/** RIGHT MARGINS */
.m-r-1 {
  margin-bottom: 1px;
}
.m-r-5 {
  margin-bottom: 5px;
}
.m-r-10 {
  margin-bottom: 10px;
}
.m-r-15 {
  margin-bottom: 15px;
}
.m-r-20 {
  margin-bottom: 20px;
}
.m-r-25 {
  margin-bottom: 25px;
}
.m-r-30 {
  margin-bottom: 30px;
}
.m-r-40 {
  margin-bottom: 40px;
}
.m-r-50 {
  margin-bottom: 50px;
}
.m-r-100 {
  margin-bottom: 100px;
}

.w-80 {
  width: 80%;
}

.bold {
  font-weight: bold;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 300px white inset !important;
}

input:-webkit-autofill{
  -webkit-text-fill-color: black !important;
}

ion-menu:host {
  --side-min-width: 60px;
  --side-max-width: 28%;
}

ion-alert .alert-radio-group {
  border-top: none !important;
}
