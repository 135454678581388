ion-footer::before {
  background: none !important;
}

ion-label {
  z-index: 100000;
}

.patient-details-page ion-item {
  border-top: 1px solid #F0F0F0;
  border-left: 1px solid #F0F0F0;
  border-right: 1px solid #F0F0F0;
  border-radius: 5px;
}

.seat-user-confirmation {
  border: 2px solid var(--ion-border-color);
  border-radius: 6px;
  width: 200px;
  margin: auto;
  min-height: 200px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.seat-user-confirmation.warning {
  border-color: var(--ion-color-warning-shade);
}

.seat-user-confirmation ion-text {
  text-align: center;
}

.seat-user-confirmation.warning ion-text {
  text-align: center;
  color: var(--ion-color-warning-shade);
}

.seat-user-confirmation.currently-paired-patient {
  float: right;
  margin-right: 10px;
}

.seat-user-confirmation.pair-different-patient {
  float: left;
  margin-left: 10px;
}

.seat-user-confirmation.pair-different-patient ion-text.helper-text {
  margin-bottom: 0;
}

.seat-user-confirmation ion-text.helper-text {
  font-family: "Montserrat",sans-serif;
  font-size: 14px;
  margin-bottom: 24px;
}

.seat-user-confirmation ion-text.patient-name {
  font-family: "Montserrat",sans-serif;
  font-size: 20px;
  margin-bottom: 24px;
  font-weight: bold;
  color: #454545;
}

.patient-details-form {
  overflow-y: scroll;
}

@media (max-width:602px)  {
  .btn-scan-code {
    margin-left: 10px;
    max-width: 100px;
  }
}
