.ts {
  position: absolute;
  top: 25px;
  right: 25px;
  border-radius: 50%;
  height:50px;
  width: 50px;
  display: block;
}

.ts.on{
  --background:var(--body-background-color);
}

.ts.off {
  --background:var(--ion-color-primary);
}

.ts.on ion-label ion-icon {
  color: var(--ion-color-primary);
}

.ts.off ion-label ion-icon {
  color: var(--body-background-color);
}

.ts ion-label{
  height: 50px;
  width: 50px;
  display: block;
}

.ts ion-label ion-icon{
  height: 60%;
  width: 100%;
}

.ts:hover{
  cursor: pointer;
}


