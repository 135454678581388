.cloud-endpoint-text {
  margin-bottom: 13px;
}
.pair-seat-instructions {
  max-width: 466px;
}

@media (max-width:602px)  {
  .btn-scan-code {
    margin-left: 10px;
    max-width: 100px;
  }
}
