/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** BODY Colors **/
  --body-background-color: #F0F0F0;
  --card-background-color: #FFFFFF;
  --ion-font-family: "Roboto",sans-serif;
  --ion-font-color: #7e7f82;
  --ion-border-color: #dbdbdb;
  --menu-width: 200px;

  /** Log styles for debug view **/
  --log-debug: #757575;
  --log-info: #6a6eff;
  --log-warn: #c63EEB;
  --log-error: #ed6c02;
  --log-panic: #f42c41;
  --log-success: #2e7d32;

  /** primary **/
  --ion-color-primary: #6a6efe;
  --ion-color-primary-rgb: 106, 110, 254;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #7e7f82;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

/** MENU SETTINGS **/
  --cas-menu-background-color: rgb(241,241,241);

/* PROGRESS VARIABLES */
  --cas-menu-color-active-color: rgb(91, 108, 236);
  --cas-menu-color-active-icon-color: rgb(228, 230, 246);

  --cas-menu-color-visited-color: rgb(150, 160, 240);
  --cas-menu-color-visited-icon-color: rgb(228, 230, 246);

  --cas-menu-color-hover-color: rgb(175,174,174);
  --cas-menu-color-hover-icon-color: rgb(206,207,208);

  --cas-menu-color-inactive-color: rgb(195,194,194);
  --cas-menu-color-inactive-icon-color: rgb(226,237,238);

  --cas-menu-color-disabled-color: rgb(215,214,214);
  --cas-menu-color-disabled-icon-color: rgb(246,247,248);

  /* CUSTOM FORM STYLE */
  --cas-input-color: var(--ion-color-medium);
  --cas-input-background: var(--ion-color-dark-contrast);
}

