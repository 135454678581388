ion-modal.casana-modal {
  --max-height: 320px;
  --max-width: 535px;
  --background: var(--body-background-color);
}


ion-modal.casana-modal p, ion-modal.casana-modal h1 {
  font-family: "Montserrat", sans-serif;
}

ion-modal.casana-modal ion-button {
  margin: 20px auto;
}


ion-modal.casana-modal ion-button button {
  background-color: var(--ion-color-primary);
  border-radius: 5px;
}
